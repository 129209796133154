import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { IRootState } from 'config/store';
import NanoDashboardGridItem from 'modules/dashboard/grid/NanoDashboardGridItem';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { nanolikeDataType } from 'shared/model/api.model';
import { graphType, IGraph } from 'shared/model/graph.model';
import {
  getTimeOffSetI18nKey,
  ONE_DAY,
  FOUR_MONTH,
  ONE_MONTH,
  ONE_WEEK,
  THREE_MONTH,
  TWO_WEEK
} from 'shared/utils/date-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { useWorkspaceType, workspaceIsSilo } from 'shared/utils/workspace-utils';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import SelectDevicesOrGroups from 'shared/widgets/form/selectDevicesOrGroups';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';
import GraphType from 'shared/widgets/visualization/graphType';
import { getGraphFromResponse } from './createOrEditGraphForm';
import { getEnvWorkspace } from 'config/env';
import { useLocalizedDataTypeWithUnit } from 'shared/utils/lang-utils';

export const dataOnlyForFront: nanolikeDataType[] = ['device_content', 'location'];
const dataTypeForCurve: nanolikeDataType[] = ['level_percent'];
const onlyForSiloCurve: nanolikeDataType[] = ['level_t'];
const onlyForIbcCurve: nanolikeDataType[] = ['temperature', 'level_liter'];
const baseDataTypeForTable: nanolikeDataType[] = ['level', 'lastMessageReceived'];
const gachesExclusiveDataType: nanolikeDataType[] = [
  // because gache has a specifid useCase with suez Cuve
  'capa_max',
  'missingLiter',
  'missingLiterJPlus1',
  'missingLiterJPlus2',
  'missingLiterJPlus3',
  'missingLiterJPlus4',
  'missingLiterJPlus5',
  'missingLiterJPlus6',
  'missingLiterJPlus7'
];
const dataTypeForTable: { [key: string]: nanolikeDataType[] } = {
  silo: [...baseDataTypeForTable, 'levelPredictions', 'remainingDays', 'capa_max', 'missingWeight'],
  silo_industry: [...baseDataTypeForTable, 'capa_max', 'missingWeight'],
  ibc: [
    ...baseDataTypeForTable,
    'levelPredictions',
    'remainingDays',
    'temperature',
    'fillingDate',
    'movement'
  ]
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    toggleButton: {
      height: '100%',
      width: '100px'
    },
    icon: {
      width: '100%',
      height: '32px'
    },
    graphType: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      color: theme.palette.primary.main,
      width: '90px',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '6px',
      padding: '0.5rem',
      border: '1px solid',
      borderColor: theme.palette.primary.main
    }
  })
);

export interface IGraphFormSecondStepProps {
  step: number;
  activeStep: number;
}

const GraphFormSecondStep = (props: IGraphFormSecondStepProps) => {
  const { activeStep, step } = props;
  const { t } = useTranslation();
  const { localizedDataTypeWithUnit } = useLocalizedDataTypeWithUnit();
  const form = useFormContext();
  const classes = useStyles();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const useCase = useWorkspaceType(settings);
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;
  const [graph, setGraph] = useState<IGraph>();
  const [selectedDevices, setSelectedDevices] = useState<ILabelValueOption[]>([]);
  const workspace = getEnvWorkspace();

  const deviceGroupDefaultValue = !isNew ? form.getValues().device_group : undefined;
  const deviceDataTypesDefaultValue = !isNew ? form.getValues().device_data_types : undefined;
  const startOffsetTimeDefaultValue = !isNew ? form.getValues().start_offset_time : undefined;
  const selectedGraphType = form.getValues().graphType as graphType;

  useEffect(() => {
    if (activeStep !== step) {
      setGraph(undefined);
    } else if (deviceGroupDefaultValue) {
      setSelectedDevices(deviceGroupDefaultValue);
      onDeviceOrGroupChange(deviceGroupDefaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, deviceGroupDefaultValue, step]);

  const groupGroup = t('group');
  const getTypesOptions = () => {
    if (activeStep === step) {
      let allowedDataGraphTypes: nanolikeDataType[] = [];
      if (selectedGraphType === 'table') {
        allowedDataGraphTypes = [...dataTypeForTable[useCase], ...dataOnlyForFront];
        if (workspace === 'gaches')
          allowedDataGraphTypes = allowedDataGraphTypes.concat(gachesExclusiveDataType); // handle gaches dirty
      } else if (selectedGraphType === 'curve')
        allowedDataGraphTypes = [
          ...dataTypeForCurve,
          ...(isSilo ? onlyForSiloCurve : onlyForIbcCurve)
        ];

      const typesOptions = allowedDataGraphTypes.map((type: nanolikeDataType) => ({
        value: type,
        label: localizedDataTypeWithUnit(type)
      }));

      return typesOptions;
    }
    return [];
  };

  const onDeviceOrGroupChange = (options: ILabelValueOption | ILabelValueOption[]) => {
    if (options) {
      const selectedOptions = Array.isArray(options) ? options : [options];
      setSelectedDevices(selectedOptions);
    } else {
      setSelectedDevices([]);
    }
  };
  const launchPreview = () => {
    let dataType: nanolikeDataType[] = [];
    if (form.getValues().device_data_types) {
      if (Array.isArray(form.getValues().device_data_types)) {
        dataType = form
          .getValues()
          .device_data_types.map((option: ILabelValueOption) => option.value);
      } else {
        dataType.push(form.getValues().device_data_types.value);
      }
    }

    if (selectedGraphType === 'map') dataType = ['position'];

    const selectedDevices = Array.isArray(form.getValues().device_group)
      ? form.getValues().device_group
      : [form.getValues().device_group];
    const metricText = form.getValues().metric_text ? form.getValues().metric_text : '';
    const startOffsetTime = form.getValues().start_offset_time
      ? form.getValues().start_offset_time.value
      : undefined;

    const graph = getGraphFromResponse(
      selectedGraphType,
      selectedDevices,
      groupGroup,
      dataType,
      metricText,
      startOffsetTime
    );
    setGraph(graph);
  };

  let label = t('select_device');

  const timeOptions: ILabelValueOption[] = [
    {
      label: t(getTimeOffSetI18nKey(ONE_DAY)),
      value: ONE_DAY
    },
    {
      label: t(getTimeOffSetI18nKey(ONE_WEEK)),
      value: ONE_WEEK
    },
    {
      label: t(getTimeOffSetI18nKey(TWO_WEEK)),
      value: TWO_WEEK
    },
    {
      label: t(getTimeOffSetI18nKey(ONE_MONTH)),
      value: ONE_MONTH
    },
    {
      label: t(getTimeOffSetI18nKey(THREE_MONTH)),
      value: THREE_MONTH
    },
    {
      label: t(getTimeOffSetI18nKey(FOUR_MONTH)),
      value: FOUR_MONTH
    }
  ];

  return (
    <Box display={activeStep === step ? 'block' : 'none'}>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} md={4} lg={2}>
          <Box>
            <GraphType type={selectedGraphType} className={classes.graphType} />
          </Box>
          <TextField
            autoFocus
            margin="dense"
            id="graph_name"
            label={t('choose_graph_name')}
            fullWidth
            name="graph_name"
            inputRef={form.register({
              validate: value => {
                if (activeStep === step && !value) {
                  return <Trans i18nKey="required_field">Required Field</Trans>;
                }
                return true;
              }
            })}
            error={form.errors.graph_name ? true : false}
            helperText={form.errors.graph_name && form.errors.graph_name.message}
          />
          <SelectDevicesOrGroups
            step={step}
            activeStep={activeStep}
            name="device_group"
            label={label}
            onSelectionChange={onDeviceOrGroupChange}
            disabled={false}
            selectAll
          />
          {selectedGraphType !== 'map' && (
            <>
              <MultipleAutoComplete
                name="device_data_types"
                label={t('select_data')}
                defaultValue={deviceDataTypesDefaultValue}
                options={getTypesOptions()}
                validate={(value: any) => {
                  if (activeStep === step && !value) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }}
              />
              {selectedGraphType === 'curve' && (
                <>
                  <SingleAutoComplete
                    name="start_offset_time"
                    label={t('select_time_start_offset_time')}
                    defaultValue={startOffsetTimeDefaultValue}
                    options={timeOptions}
                    disableClearable={true}
                    validate={(value: any) => {
                      if (activeStep === step && !value) {
                        return <Trans i18nKey="required_field">Required Field</Trans>;
                      }
                      return true;
                    }}
                  />
                </>
              )}
            </>
          )}
          <Box pt={2} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              endIcon={<PlayIcon />}
              onClick={launchPreview}
              disabled={selectedDevices.length === 0}
            >
              {t('preview')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={10}>
          <Box p={2} height="300px">
            {graph && <NanoDashboardGridItem graph={graph} preview />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GraphFormSecondStep;
