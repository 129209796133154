import { IRootState } from 'config/store';
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { IDataTableColumn } from 'react-data-table-component';
import DataTable from 'shared/widgets/dataTable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AutoOrderHistory, normalizeMaterialNo } from 'shared/model/autoOrder.model';
import { fetchAutoOrdersHistory } from 'shared/reducers/autoOrdersSlice';
import { APP_LOCAL_DATE_FORMAT, APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import SearchIcon from '@material-ui/icons/Search';
import {
  Box,
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  useTheme
} from '@material-ui/core';
import GroupFilter from 'shared/widgets/groups/groupFilter';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { DatePicker } from '@material-ui/pickers';
import StatusFilter from 'shared/widgets/autoorders/statusFilter';
import { workspaceTruckQuantity } from 'shared/utils/workspace-utils';
import ProductFilter from 'shared/widgets/products/productFilter';
import useInterval from 'shared/hooks/useInterval';
import isTruthy from 'shared/utils/isTruthy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      height: '29px',
      maxWidth: 120,
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    searchField: {
      marginTop: 0,
      width: 120,
      marginBottom: theme.spacing(1)
    }
  })
);

const AutoOrdersMonitoring = () => {
  const { t } = useTranslation();
  const loading = useSelector(({ autoOrder }: IRootState) => autoOrder.loading);
  const autoOrderHistory = useSelector(({ autoOrder }: IRootState) => autoOrder.autoOrderHistory);
  const dispatch = useDispatch();
  const devices = useSelector(({ devices }: IRootState) => devices.devices);
  const products = useSelector(({ product }: IRootState) => product.products);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const truckQuantity = workspaceTruckQuantity(settings);
  const classes = useStyles();
  const [groupsFilter, setGroupsFilter] = useState<string[]>([]);
  const [productsFilter, setProductsFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('null');
  const [startDate, setStartDate] = useState(moment().subtract(2, 'month').startOf('day'));
  const [searchQuery, setSearchQuery] = useState<string>('');
  const theme = useTheme();
  const columns: IDataTableColumn<AutoOrderHistory>[] = useMemo(() => {
    const knownErrorCode = {
      10401: t('zonda.zero_dispocard'),
      10201: t('zonda.mandatory_field_missing'),
      10202: t('zonda.mandatory_field_error'),
      10101: t('zonda.technical_error'),
      99001: t('zonda.shipto_field_missing'),
      99002: t('zonda.soldto_field_missing'),
      99003: t('zonda.product_name_field_missing'),
      99004: t('zonda.product_number_field_missing'),
      99005: t('zonda.no_contract_material')
    };
    return [
      {
        selector: 'created_at',
        name: t('created_at'),
        sortable: true,
        grow: 1.5,
        format: (row: any) => {
          return formatDate(row.created_at, APP_TIMESTAMP_FORMAT);
        }
      },
      {
        selector: 'orderNo',
        name: t('order_number'),
        sortable: true,
        grow: 2.5,
        format: row => {
          const isSuccess = row.code === 10000 && row.orderNo !== null;
          const text: string = isSuccess
            ? String(row.orderNo)
            : row.code !== null
            ? knownErrorCode[row.code as keyof typeof knownErrorCode]
            : t('autoorder_not_created');

          return (
            <Tooltip title={isSuccess ? '' : text}>
              <Box display="flex" alignItems="center">
                {text}
              </Box>
            </Tooltip>
          );
        }
      },
      {
        selector: 'productNo',
        name: t('reference'),
        sortable: true,
        grow: 2,
        format: row => {
          return (
            <Box display="flex" alignItems="center">
              {row.materialNo}
            </Box>
          );
        }
      },
      {
        selector: 'product',
        name: t('device_content'),
        sortable: true,
        grow: 2,
        format: row => {
          const materialName = products.find(
            product =>
              normalizeMaterialNo(product.device_content_reference) ===
              normalizeMaterialNo(row.materialNo)
          )?.device_content;
          return (
            <Box display="flex" alignItems="center">
              {materialName}
            </Box>
          );
        }
      },
      {
        selector: 'quantity',
        name: t('string_workspace_filling_unit', { value: t('ordered_quantity') }),
        sortable: true,
        grow: 1.5,
        format: () => (
          <Box display="flex" alignItems="center">
            {truckQuantity}
          </Box>
        )
      },
      {
        selector: 'plant',
        name: t('plant'),
        sortable: true,
        grow: 3,
        format: row => {
          const siteName = devices.find(
            device => device.device_reference === row.idDevice
          )?.farm_name;
          return (
            <Box display="flex" alignItems="center">
              {siteName}
            </Box>
          );
        }
      },
      {
        id: 'siloName',
        selector: 'idDevice',
        name: t('silo'),
        sortable: true,
        grow: 2,
        format: row => {
          const deviceName = devices.find(
            device => device.device_reference === row.idDevice
          )?.device_name;
          return (
            <Box display="flex" alignItems="center">
              {deviceName}
            </Box>
          );
        }
      }
    ];
  }, [t, devices, truckQuantity, products]);

  const loadData = useCallback(async () => {
    dispatch(fetchAutoOrdersHistory(startDate));
  }, [dispatch, startDate]);

  useInterval(loadData);

  const title = React.useMemo(() => {
    const onChange = (search: string) => {
      setSearchQuery(search);
    };
    const onGroupsChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setGroupsFilter(filter);
    };
    const onProductChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setProductsFilter(filter);
    };
    const onStatusChange = (event: React.ChangeEvent<any>) => {
      setStatusFilter(event.target.value);
    };
    return (
      <Box display="flex" flexDirection="row" justifyContent="start">
        {t('auto_orders_history')}

        <DatePicker
          disableToolbar
          value={startDate}
          onChange={(value: any) => setStartDate(value)}
          format={APP_LOCAL_DATE_FORMAT}
          disableFuture
          margin="none"
          variant="inline"
          className={classes.datePicker}
        />

        <TextField
          className={classes.searchField}
          size="small"
          placeholder={t('search')}
          onChange={e => onChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            )
          }}
        />
        <ProductFilter onChange={onProductChange} />
        <GroupFilter onChange={onGroupsChange} />
        <StatusFilter status={statusFilter} onChange={onStatusChange} />
      </Box>
    );
  }, [t, classes, startDate, statusFilter]);

  const filteredData = useMemo(() => {
    const getAutoOrderDevice = (autoorder: AutoOrderHistory) => {
      return devices.find(device => device.device_reference === autoorder.idDevice);
    };

    const filterByGroup = (autoorder: AutoOrderHistory): boolean => {
      if (groupsFilter.length > 0) {
        const device = getAutoOrderDevice(autoorder);
        return Boolean(device && device.farm_id && groupsFilter.includes(device.farm_id));
      }
      return true;
    };

    const filterByProducts = (autoorder: AutoOrderHistory): boolean => {
      if (productsFilter.length > 0) {
        return Boolean(
          autoorder?.materialNo &&
            productsFilter
              .map(normalizeMaterialNo)
              .includes(normalizeMaterialNo(autoorder?.materialNo))
        );
      }
      return true;
    };

    const filterByStatus = (autoorder: AutoOrderHistory): boolean => {
      if (statusFilter === 'null') return true;
      if (statusFilter === 'KO') return autoorder.code !== 10000 && autoorder.orderNo === null;
      if (statusFilter === 'OK') return autoorder.code === 10000 && autoorder.orderNo !== null;
      return true;
    };

    const filterBySearch = (autoorder: AutoOrderHistory): boolean => {
      if (searchQuery.trim().length === 0) return true;

      const device = getAutoOrderDevice(autoorder);
      const materialName = products.find(
        product =>
          normalizeMaterialNo(product.device_content_reference) ===
          normalizeMaterialNo(autoorder.materialNo)
      )?.device_content;

      const searchableValues = [
        formatDate(autoorder.created_at, APP_TIMESTAMP_FORMAT),
        device?.device_name,
        device?.farm_name,
        materialName,
        autoorder.materialNo,
        autoorder.orderNo?.toString()
      ].filter(isTruthy);

      const matchesSearchQuery = (value: string) =>
        value.trim().toLowerCase().includes(searchQuery.trim().toLowerCase());

      return searchableValues.some(matchesSearchQuery);
    };

    return autoOrderHistory
      .filter(filterBySearch)
      .filter(filterByGroup)
      .filter(filterByProducts)
      .filter(filterByStatus);
  }, [
    autoOrderHistory,
    devices,
    products,
    searchQuery,
    groupsFilter,
    productsFilter,
    statusFilter
  ]);

  const conditionalRowStyles = [
    {
      when: (row: AutoOrderHistory) => row.code !== 10000 && row.orderNo === null,
      style: {
        backgroundColor: theme.palette.error.light
      }
    }
  ];

  return (
    <DataTable
      title={title}
      defaultSortField="created_at"
      defaultSortAsc={false}
      columns={columns}
      data={filteredData}
      progressPending={loading}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default AutoOrdersMonitoring;
