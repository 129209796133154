import moment from 'moment';
import { nanolikeDataType } from 'shared/model/api.model';
import {
  IFilterCriterionValue,
  operatorType,
  valueType,
  graphType
} from 'shared/model/graph.model';
import isTruthy from 'shared/utils/isTruthy';

export interface IFilterCriterionDef {
  key: nanolikeDataType | 'device_content_id' | 'group_ids' | 'last_activity' | 'status';
  i18n?: string;
  operators: operatorType[];
  valueType: valueType;
  valueOptions?: { i18n: string; value: any }[];
  defaultValue: any;
  convertToApiFilter?: (filterValue: IFilterCriterionValue) => IFilterCriterionValue | null;
}

const levelPercentDef: IFilterCriterionDef = {
  key: 'level_percent',
  operators: ['<=', '>='],
  valueType: 'number_positive',
  defaultValue: ''
};

const remainingDaysDef: IFilterCriterionDef = {
  key: 'remainingDays',
  operators: ['<=', '>='],
  valueType: 'number_positive',
  defaultValue: ''
};

const temperatureDef: IFilterCriterionDef = {
  key: 'temperature',
  operators: ['<=', '>='],
  valueType: 'number',
  defaultValue: ''
};

const deviceContentDef: IFilterCriterionDef = {
  key: 'device_content_id',
  i18n: 'device_content',
  operators: ['in', 'not in'],
  valueType: 'multiselect',
  defaultValue: []
};

const missingWeightDef: IFilterCriterionDef = {
  key: 'missingWeight',
  i18n: 'missingWeight',
  operators: ['>=', '<='],
  valueType: 'number',
  defaultValue: ''
};

const lastActivityDef: IFilterCriterionDef = {
  key: 'last_activity',
  i18n: 'filter_last_activity_days',
  operators: ['<=', '>='],
  valueType: 'number', // days
  defaultValue: '',
  convertToApiFilter: filterValue => {
    const timestamp = moment().subtract(Number(filterValue.value), 'days').toISOString();
    const operator = filterValue.operator === '<=' ? '>=' : '<=';
    return { key: 'timestamp', operator, value: timestamp };
  }
};

const statusDef = (graphType: graphType, workspaceType: string): IFilterCriterionDef => {
  const needsPosition = graphType === 'map';
  const isSilo = workspaceType.includes('silo');

  return {
    key: 'status',
    i18n: 'filter_status',
    operators: [], // do not let them choose, special handling: we will send the "in" operator
    valueType: 'select',
    defaultValue: '', // Empty select
    valueOptions: [
      {
        i18n: ' ', // empty option
        value: ''
      },
      { i18n: 'all', value: 'all' },
      { i18n: 'devices_status.ok.label', value: 'ok' },
      {
        i18n: isSilo ? 'devices_status.pending.label' : 'devices_status.level_problem.label',
        value: 'problem'
      },
      !needsPosition && { i18n: isSilo ? 'devices_status.error.label' : 'KO', value: 'ko' }
    ].filter(isTruthy),
    convertToApiFilter: filterValue => {
      switch (filterValue.value) {
        case 'ok':
          return { key: 'status', operator: 'in', value: ['ok'] };
        case 'problem':
          return { key: 'status', operator: 'in', value: ['level_problem'] };
        case 'ko':
          return { key: 'status', operator: 'in', value: ['problem'] };
        case 'all':
          return {
            key: 'status',
            operator: 'in',
            value: ['ok', 'level_problem', !needsPosition && 'problem'].filter(isTruthy)
          };
        default:
          console.error('Unknown status value', filterValue.value);
          return null;
      }
    }
  };
};

/**
 *  Returns the available filters for a given graph type and workspace type
 */
export const getAvailableFilters = (graphType: graphType, workspaceType: string) => {
  const isIbc = workspaceType === 'ibc';
  const isSilo = workspaceType.includes('silo');
  const isSiloIndus = workspaceType === 'silo_industry';

  switch (graphType) {
    case 'table':
      return [
        isIbc && statusDef(graphType, workspaceType), //
        isSiloIndus && missingWeightDef,
        levelPercentDef,
        isSilo && deviceContentDef,
        isIbc && remainingDaysDef,
        isIbc && temperatureDef,
        isIbc && lastActivityDef
      ].filter(isTruthy);
    case 'map':
      return [
        isIbc && statusDef(graphType, workspaceType), //
        levelPercentDef,
        isIbc && remainingDaysDef,
        isIbc && lastActivityDef
      ].filter(isTruthy);
    default:
      return [];
  }
};

/**
 * Convert filter and their values as stored in a graph, to client filters that the API understands.
 * Most filters match exactly those in the API and do not need conversion.
 */
export function convertToApiFilters(
  graphType: graphType,
  workspaceType: string,
  filterValues: IFilterCriterionValue[]
): IFilterCriterionValue[] {
  const filterDefs = getAvailableFilters(graphType, workspaceType);

  const apiFilters = filterValues
    .map(filterValue => {
      const def = filterDefs.find(def => def.key === filterValue.key);

      if (def && def.convertToApiFilter) {
        return def.convertToApiFilter(filterValue);
      } else {
        return filterValue;
      }
    })
    .filter(isTruthy);

  const statusFilter = apiFilters.find(filter => filter.key === 'status');
  if (!statusFilter) {
    const requiresOk = filterValues.some(filterValue =>
      ['level_percent', 'remainingDays', 'temperature', 'missingWeight'].includes(filterValue.key)
    );

    if (requiresOk) {
      apiFilters.push({ key: 'status', operator: 'in', value: ['ok'] });
    } else if (graphType === 'map') {
      apiFilters.push({ key: 'status', operator: 'in', value: ['ok', 'level_problem'] });
    }
  }

  return apiFilters;
}

// We store the operator and value for a filter in react-hook-form
// in properties key___operator and key___value
const criterionFormSeparator = '___';

/**
 * Unique key to store the value of the operator in a react-hook-form
 */
export function getFilterOperatorFormKey(key: string) {
  return `${key}${criterionFormSeparator}operator`;
}

/**
 * Unique key to store the value of the filter in a react-hook-form
 */
export function getFilterValueFormKey(key: string) {
  return `${key}${criterionFormSeparator}value`;
}

/**
 * @returns the filter key for the form key
 */
export function getFilterKeyFromFormKey(formKey: string) {
  return formKey.split(criterionFormSeparator)[0];
}
